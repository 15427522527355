.bottominfo {
  color: #fff;
  @include media-picker-image-pick(
    "png",
    "/assets/images/backgrounds/mountainhike/hike"
  );
  background-position: bottom left;
  background-color: #00233a;
  background-repeat: no-repeat;
  padding: 3rem 0.5rem;
  font-size: 1.4rem;

  a,
  a:visited,
  a:active {
    color: #fff;
  }

  h1 {
    box-shadow: none;
    /*text-shadow: 4px 4px #000;*/
    text-shadow: 3px 3px 0 #000,
      /* Simulated effect for Firefox and Opera
         and nice enhancement for WebKit */ -1px -1px
        0 #000,
      1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  }

  .bottom-info-address {
    margin: 3rem 0;
  }
}

.storeHours {
  padding: 3rem 0;
  font-size: 1.4rem;
  width: 100%;
  background-color: #013034;
  color: #68979c;
  box-shadow: 0 4px 2px -2px #000;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  h1 {
    text-align: center;
    color: #68979c;
    margin-top: 0;
    padding-top: 0;
    border-bottom: 1px solid #68979c;
    width: 100%;
    padding-bottom: 0.8rem;
    margin-bottom: 0.8rem;
  }

  table {
    width: 100%;
    border: 0;
    padding: 0;
    border-spacing: 20px 0;
    border-collapse: separate;

    tr {
      height: 2.5rem;
    }

    .days {
      width: 50%;
      text-align: right;
      margin-right: 2%;
      padding: 0;
    }

    .hours {
      width: 50%;
      text-align: left;
      margin-left: 2%;
      padding: 0;
    }
  }
}

.fb-page {
  flex-shrink: 0;
  flex-grow: 1;
  // flex: 1;
  background-color: #fff;
  padding: 0;
  width: 500px;
  height: 600px;
  margin-top: 1rem;
  text-align: center;
}

.main-index-content {
  font-size: 1.4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0 !important;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
  @media (min-width: 1001px) {
    flex-wrap: nowrap;
  }
}

.index-paragraphs {
  padding: 0 0.5rem;  
  border-top: 1px solid #e6e6e6;
  padding-top: 0.5rem;
  margin: 1rem;
  p:first-child {
    margin-top: 0;
  }
}
