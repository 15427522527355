﻿$desktop-break: 1000;
$desktop-break-2: 1279;
$desktop-break-3: 1439;
$desktop-break-4: 1599;
$desktop-break-5: 1919;
$desktop-break-6: 2199;
$desktop-break-7: 2599;

$loopbreaks: $desktop-break $desktop-break-2 $desktop-break-3 $desktop-break-4 $desktop-break-5 $desktop-break-6 $desktop-break-7;

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin media-query($min, $max) {
    @if $min == 'none' {
        @media (max-width: $tablet-break + px) {
            @content;
        }
    }
    @else if $max == 'none' {
        @media (min-width: ($desktop-break-5 + 1) + px) {
            @content;
        }
    }
    @else {
        @media (min-width: ($min + 1) + px) and (max-width: $max + px) {
            @content;
        }
    }
}

// Does percentage scaling of numeric properties in px based on a known resolution break and chosen font size
@mixin media-query-scale-loop($property, $pivotsize, $pivotpropertysize) {

    @for $i from 1 through length($loopbreaks) {
        $break: nth($loopbreaks, $i);

        @if $i == 1 {
            @media (max-width: $break + px) {
                #{$property}: #{round($pivotpropertysize * ($break / $pivotsize))}px;
            }

            @media (min-width: ($break + 1) + px) and (max-width: (nth($loopbreaks, $i + 1)) + px ) {
                #{$property}: #{round($pivotpropertysize * ($break / $pivotsize))}px;
            }
        }
        @else if $i == length($loopbreaks) {
            @media (min-width: ($break + 1) + px) {
                #{$property}: #{round($pivotpropertysize * ($break / $pivotsize))}px;
            }
        }
        @else {
            @media (min-width: ($break + 1) + px) and (max-width: (nth($loopbreaks, $i + 1)) + px ) {
                #{$property}: #{round($pivotpropertysize * ($break / $pivotsize))}px;
            }
        }
    }
}

// Does percentage scaling of font-sizes in px based on a known resolution break and chosen font size
@mixin media-query-scale-loop-min-max($property, $pivotsize, $pivotpropertysize, $min, $max) {

    @for $i from 1 through length($loopbreaks) {
        $break: nth($loopbreaks, $i);

        $breaksize: round($pivotpropertysize * ($break / $pivotsize));

        @if $breaksize > $max {
            $breaksize: $max;
        }
        @else if $breaksize < $min {
            $breaksize: $min;
        }

        @if $i == 1 {
            @media (max-width: $break + px) {
                #{$property}: #{$breaksize}px;
            }

            @media (min-width: ($break + 1) + px) and (max-width: (nth($loopbreaks, $i + 1)) + px ) {
                #{$property}: #{$breaksize}px;
            }
        }
        @else if $i == length($loopbreaks) {
            @media (min-width: ($break + 1) + px) {
                #{$property}: #{$breaksize}px;
            }
        }
        @else {
            @media (min-width: ($break + 1) + px) and (max-width: (nth($loopbreaks, $i + 1)) + px ) {
                #{$property}: #{$breaksize}px;
            }
        }
    }
}

@mixin media-picker-image-pick($fileextension, $prefix) {
    @for $i from 1 through length($loopbreaks) {
        $break: nth($loopbreaks, $i);
        $dindex: $i;

        @if $i == 1 {
            @media (max-width: (nth($loopbreaks, $i + 1)) + px) {
                background-image: url('#{$prefix}d.#{$fileextension}');
            }
        }
        @else if $i == length($loopbreaks) {
            @media (min-width: ($break + 1) + px) {
                background-image: url('#{$prefix}d#{$dindex}.#{$fileextension}');
            }
        }
        @else {
            @media (min-width: ($break + 1) + px) and (max-width: (nth($loopbreaks, $i + 1)) + px ) {
                background-image: url('#{$prefix}d#{$dindex}.#{$fileextension}');
            }
        }
    }
}