﻿@import "_responsive";

$font-stack: Sanchez, Segoe UI, sans-serif;

html {
  @media (min-width: 1001px) and (max-width: 1200px) {
    font-size: 16px;
  }
  @media (min-width: 1201px) {
    font-size: 20px;
  }
  @media (max-width: 1000px) {
    font-size: 30px;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-stack;
  background-color: #fff;
  color: #111;
  font-size: 1rem;
  line-height: 1.3;
}

.center {
  text-align: center;
}

h1 {
  font-size: 2.6rem;
}

h2 {
  font-size: 2.2rem;
}

h3 {
  font-size: 1.8rem;
}

h4 {
  font-size: 1.4rem;
}

h5 {
  font-size: 1.2rem;
}

p {
  margin: 2rem auto;
}

.clear {
  clear: both;
}

div.main-page-header {
  padding: 1.5rem 0;
  margin-top: 0;
  background-color: #091720;
  color: #fff;

  h1 {
    margin: 0;
    padding: 0;
  }
}

.logobg {
  padding: 0;
  // @include media-query-scale-loop("padding-top", $desktop-break-5, 10);
  font-weight: bold;
  @media (min-width: 1001px) {
    height: 30rem;
  }
  @media (max-width: 1000px) {
    height: 15rem;
  }
  
  width: 100%;
  @include media-picker-image-pick(
    "jpg",
    "/assets/images/backgrounds/tent/tent"
  );
  background-repeat: repeat-x;
  background-position: bottom left;
  text-align: center;
  box-shadow: 0 4px 2px -2px #000;

  @media (max-width: 1000px) {
    margin-top: 6rem;
  }

  .banner {
    margin: 0 auto;
    display: block;
    @include media-picker-image-pick("png", "/assets/images/ab/ab");
    // @include media-query-scale-loop("height", $desktop-break, 396);
    @media (min-width: 1001px) {
      height: 30rem;
    }
    @media (max-width: 1000px) {
      height: 15rem;
    }
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
  }
}

.helper {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 0;
}

.contentArea {
  /*background-color: #fff;*/
  margin: 0 auto;
  @include media-query-scale-loop("width", $desktop-break-5, 1800);
}

.waterfall {
  @include media-query-scale-loop("height", $desktop-break-5, 394);
  @include media-query-scale-loop("width", $desktop-break-5, 700);
  @include media-picker-image-pick("jpg", "/assets/images/waterfall/waterfall");
}

.waterfallCard {
  @include media-query-scale-loop("width", $desktop-break-5, 700);
  @include media-query-scale-loop("margin-left", $desktop-break-5, 20);
  float: right;

  .card-image {
    .card-title {
      @include media-query-scale-loop("font-size", $desktop-break-5, 35);
      @include media-query-scale-loop("padding", $desktop-break-5, 20);
    }
  }
}

.lusk-card {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

footer {
  background-color: #121212;
  color: #fff;

  .footer-div {
    font-size: 1.2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1000px) {
      flex-wrap: wrap;
    }
    @media (min-width: 1001px) {
      flex-wrap: nowrap;
    }

    div.center {      
      margin-top: 2rem;
      margin-bottom: 2rem;

      @media (max-width: 1000px) {
        width: 100%;
        font-size: 1.5rem;
      }
      
    }
  }

  a,
  a:visited,
  a:active {
    color: #fff;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
    color: #fff;
  }

  div.copy {
    display: block;
    text-align: center;
    width: 100%;
    margin-top: 3rem;
    padding-bottom: 2rem;
    background-color: #121212;
    font-size: 1.3rem;
  }
}
