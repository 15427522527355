﻿@import "_responsive";

.menubar {
  width: 100%;
  background-color: #360900;

  nav {
    ul {
      padding: 0;
      height: 100%;

      li {
        /*display: inline-block;*/
        float: left;
        width: auto;
        height: 100%;
        margin: 0;
        list-style-type: none;
        margin-right: 1.5rem;

        a,
        a:hover,
        a:visited,
        a:active {
          transition: color 0.4s;
          display: block;
          height: 100%;
          padding-top: 0;
          padding-bottom: 0;
          text-decoration: none;
          color: #ffd2aa;
        }

        a:hover {
          color: #fff;
        }

        a.activeRoute {
          color: #fff;
        }
      }
    }
  }
}

.menubar.expanded {
  font-size: 1.7rem;
  padding: 2rem 0;

  ul {
    display: block;
    width: 100%;
    margin: 0 auto;

    /*@include media-query-scale-loop('margin-top', $desktop-break-5, 20);*/
    /*@include media-query-scale-loop('margin-bottom', $desktop-break-5, -20);*/
  }

  .nav-container {
    // height: 4.6rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }
}

.menubar.collapsed {
  font-size: 1.3rem;
  padding: 0.3rem 0;
  position: fixed;
  margin-top: 0;
  top: 0;
  z-index: 999;

  nav ul {
    display: block;
    margin: 0 auto;
    /*@include media-query-scale-loop('margin-top', $desktop-break-5, 5);
            @include media-query-scale-loop('margin-bottom', $desktop-break-5, -5);*/
  }
}

.menubar {
  transition: all 1s;
  -moz-transition: all 1s; /* Firefox 4 */
  -webkit-transition: all 1s; /* Safari and Chrome */
  -o-transition: all 1s; /* Opera */
}

.mobile-nav-hamburger {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;

  svg {
    cursor: pointer;
    height: 6rem;
    width: 6rem;
    margin-right: 1rem;

    fill: #ffd2aa;

    &:hover {
      fill: #fff;
    }
  }
}

.mobile-nav-menubar {
  position: fixed;
  z-index: 999;  
  top: 0;
}